import React from 'react';

import './Loader.css';

const Loader = props => {

    let _isPageLoader = typeof props.isPageLoader !== 'undefined' && props.isPageLoader;

    return (
        <div className={`loader-wrapper ${_isPageLoader ? 'page-loader' : ''}`}>
            <div className={`loader ${props.className}`}></div>
        </div>
    );
};

export default Loader;