import React, {useEffect, useState} from 'react';

import './BreakingNews.css';
import Api from "../../Components/Axios/Api";

const BreakingNews = props => {

    const [_itemsState, _setItemsState] = useState({
        isLoading: false,
        firstTimeFetched: false,
        items: [],
    });

    const _fetchBreakingNews = async () => {
        if (!_itemsState.isLoading) {
            try {
                const _response = await Api.get('/breaking-news');
                const _data = await _response.data;
                let _items = _data.items;
                _setItemsState({
                    ..._itemsState,
                    items: _items,
                    firstTimeFetched: true,
                    isLoading: false
                });
            } catch (e) {
                _setItemsState({
                    ..._itemsState,
                    firstTimeFetched: true,
                    isLoading: false,
                });
            }
        }
    }

    const handleStickyHeight = () => {
        let _sticky = document.getElementById('stickyHeight');
        let _stickyHeight = _sticky.offsetHeight + 10;
        let _id = 'headerHeight';
        let _currentStyle = document.getElementById(_id);
        if (_currentStyle) {
            _currentStyle.remove();
        }
        let _style = document.createElement("style");
        _style.setAttribute('id', _id);
        let _styleTxt = ':root{--header-height:' + _stickyHeight + 'px}';
        document.head.appendChild(_style);
        _style.innerHTML = _styleTxt;
    }

    useEffect(() => {

        if (!_itemsState.firstTimeFetched) {
            _fetchBreakingNews().then(handleStickyHeight);
        }

        let _timer = setTimeout(() => {
            _fetchBreakingNews().then(handleStickyHeight);
        }, 25000);

        return () => {
            clearTimeout(_timer)
        };
    }, [_itemsState]);

    return (
        <React.Fragment>
            {!_itemsState.items.length ? '' : <div className="breaking--news">
                <div className="container">
                    {_itemsState.items.map((_item) => {
                        return (
                            <div className={`breaking-news--item box`} key={_item.id}>
                                <div className="breaking-news--title">خبر عاجل</div>
                                <div className="breaking-news--value">{_item.title}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            }
        </React.Fragment>
    );
};

export default BreakingNews;