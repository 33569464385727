import React from 'react';

import './Banner.css';

const Banner = props => {

    const {width, height, link, title, image} = {...props.item};
    return (
        <div className={'banner'}>
            <a href={link} target={'_blank'} title={title}>
                <img src={image} alt={title} width={width+`px`} height={height+`px`}/>
            </a>
        </div>
    );
};

export default Banner;