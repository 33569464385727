import React, {useContext} from 'react';

import './DarkMode.css';
import {BsMoonFill, BsSunFill} from 'react-icons/bs';
import DisplayContext from "../../../Context/DisplayContext";
import useQuery from "../../Hooks/useQuery";

const DarkMode = () => {

    let _dcx = useContext(DisplayContext);
    const _query = useQuery();
    let _isPrintPage = false;
    if (_query.get('action') === 'print') {
        _isPrintPage = true;
    }
    if (_isPrintPage) {
        return <React.Fragment/>;
    }

    return (
        <div className={`dark-mode ${_dcx.isDarkMode ? 'active' : ''}`} onClick={_dcx.darkModeHandler}>
            <BsMoonFill className={`dark-mode-disabled`}/>
            <BsSunFill className={`dark-mode-active`}/>
        </div>
    );
};

export default DarkMode;