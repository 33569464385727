import React from 'react';

const Image = props => {

    let _src = '';
    let {image, action, size, alt, className, height, width} = {...props};

    if (size === 'custom') {
        if (!width) {
            width = 1200;
        }
        if (!height) {
            height = 630;
        }
        _src = image[size];
        if (!action) {
            action = 'resize';
        }
        if (action === 'crop') {
            action = 'crop2';
        }
        _src = _src.replace('__ACTION__', action);
        _src = _src.replace('__WIDTH__', width);
        _src = _src.replace('__HEIGHT__', height);
    } else {
        _src = image[size];
    }
    _src += '.webp';

    return (
        <div className="image">
            <img src={_src} alt={alt} className={className} width={width} height={height}/>
        </div>
    );
};

export default Image;