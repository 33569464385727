import React from 'react';

import './../../Components/Lists/default_list.css';
import NewsItem from "../../Components/Item/NewsItem";
import Alert from "../../Components/Utilities/Alerts/Alert";
import BannersHandler from "../../Components/Utilities/BannersHandler/BannersHandler";

const MainContent = props => {

    const {_items, _isLoading, _hasFilters} = {...props};
    let _itemsList = _items;
    let _showItems = false;
    if (!_isLoading && Array.isArray(_itemsList) && _itemsList.length) {
        _showItems = true;
    }

    return (
        <React.Fragment>
            {_showItems && <div className='default-list'>
                {_itemsList.map((item, index) => {
                    if (item.type === 'article') {
                        return <NewsItem item={item} className={'default-list-item'} key={item.id + '_' + index}/>
                    }
                    if (item.type === 'banner') {
                        return <div className={`default-list-item`} key={item.id + '_' + index}>
                            <BannersHandler banners={[item]}/>
                        </div>
                    }
                })}
            </div>}
            {(!_isLoading && !_showItems && !_hasFilters) && <Alert type={`warning`} msg={`لا يوجد أخبار...`}/>}
            {(!_isLoading && !_showItems && _hasFilters) &&
                <Alert type={`warning`} msg={`لا يوجد أخبار مطابقة للتصنيفات المختارة...`}/>}
        </React.Fragment>
    );

};

export default MainContent;