import React, {useState, useEffect} from 'react';
import {FaSearch, FaTimes} from "react-icons/fa";

const SearchInput = props => {

    const [_inputState, _setInputState] = useState({
        defaultValue: '',
        showCancel: false,
        showErrors: false,
        btnClicked: false,
        lastValue: '',
        errors: [],
        lastCorrectValue:'',
        showLastCorrectValue:false,
    });

    const handleChange = (event) => {
        let _value = event.target.value;
        let _errors = [];
        if (_value.length < 4) {
            _errors.push('الرجاء إدخال كلمات بحث أطول من ثلاث أحرف!');
        }
        _setInputState({
            ..._inputState,
            defaultValue: _value,
            errors: _errors,
            showErrors: false,
        });
    }

    const handleClick = () => {
        if (!_inputState.errors.length && _inputState.defaultValue !== _inputState.lastValue) {
            _setInputState({
                ..._inputState,
                showCancel: true,
                btnClicked: true,
                showErrors: true,
                lastCorrectValue: _inputState.defaultValue,
                showLastCorrectValue: true,
                lastValue: _inputState.defaultValue,
            })
            props.onBtnClick(_inputState.defaultValue);
        } else {
            _setInputState({
                ..._inputState,
                showErrors: true,
                btnClicked: true,
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    }

    const handleClearClick = () => {
        _setInputState({
            ..._inputState,
            showCancel: false,
            lastValue: '',
            defaultValue: '',
            errors: [],
            lastCorrectValue: '',
            showLastCorrectValue: false,
        });
        props.onBtnClick('');
    }

    useEffect(() => {

        let _timer = setTimeout(() => {
            if (_inputState.btnClicked && !_inputState.showErrors) {
                _setInputState({
                    ..._inputState,
                    showErrors: true,
                });
            }
        }, 1000);

        return () => {
            clearTimeout(_timer)
        };

    }, [_inputState.defaultValue,_inputState.lastCorrectValue]);

    return (
        <div className="search-filter">
            <div className="search-wrapper">
                <input autoComplete={`off`} type="text" name={`q`} className={`search-input`}
                       onKeyDown={handleKeyDown}
                       placeholder={`ابحث في الأخبار..`} value={_inputState.defaultValue} onChange={handleChange}/>
                <button onClick={handleClick} className={`search-btn`} type={`button`}><FaSearch
                    className={`search-icon`}/></button>
                {_inputState.showErrors && (_inputState.errors.length && _inputState.defaultValue.length) ?
                    _inputState.errors.map((errorMsg, index) => {
                        return <div key={`error_${index}`} className="input-control-error">{errorMsg}</div>;
                    }) : ''
                }
                {
                    !_inputState.showLastCorrectValue ? '' :
                        <div className="input-control-tag" onClick={handleClearClick}>
                            <FaTimes className={`input-control-tag-icon`} size="13"
                                     color={'#fff'}/>
                            <span>{_inputState.lastCorrectValue}</span>
                        </div>
                }
            </div>
        </div>
    );
};

export default SearchInput;