import React from 'react';

import './Icon.css';

const Icon = props => {

    return (
        <div className={`icon ${props.className}`}>
            {props.children}
        </div>
    );
};

export default Icon;