import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import SideBar from './SideBar/SideBar';
import MainContent from './MainContent';
import Api from "../../Components/Axios/Api";
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from "../../Components/Utilities/LazyLoad/LazyLoad";
import LazyLoadPlain from "../../Components/Utilities/LazyLoad/LazyLoadPlain";
import DisplayContext from "../../Context/DisplayContext";
import WeatherArea from "../Areas/WeatherArea/WeatherArea";
import CurrencyArea from "../Areas/CurrencyArea/CurrencyArea";
import {FaFilter} from "react-icons/fa";

const Home = props => {

    const _displayContext = useContext(DisplayContext);

    const history = useHistory();
    const [_sideBarState, _setSideBarState] = useState({
        isLoading: true,
        fetched: false,
        items: [],
        hasSelectedFilters: false,
    });

    const [_itemsState, _setItemsState] = useState({
        isLoading: true,
        fetched: false,
        items: [],
        pager: {},
        page: 1,
        timerStarted: false,
        forceResetTimer: false,
        searchTxt: '',
    });

    const getSelectedFilters = () => {
        let _results = {};
        if (localStorage.getItem('_filters') && localStorage.getItem('_filters').length) {
            _results = JSON.parse(localStorage.getItem('_filters'));
        }
        if (_itemsState.searchTxt.length) {
            _results['q'] = _itemsState.searchTxt;
        }
        return _results;
    };

    const _fetchSideBar = async () => {

        const _response = await Api.get('/sidebar', {
            params: getSelectedFilters(),
        });

        const _data = await _response.data;
        _setSideBarState({
            ..._sideBarState,
            isLoading: false,
            fetched: true,
            items: _data.items,
            hasSelectedFilters: _data.hasSelectedFilters,
        });
    };

    const _updateStorageFilters = (_name, _value) => {
        let _historyParams = [];
        _value = _value + "";
        let _filters = _sideBarState.items;
        let _storageObject = {};
        let _hasSelected = false;

        _filters.map((item) => {
            if (item.type !== 'filter') {
                return false;
            }
            let _selectedIds = [];
            item.options.map((_option) => {
                let _optionId = _option.id + "";
                if (item.field_name === _name && _optionId === _value) {
                    if (_option.selected === true) {
                        _option.selected = false;
                    } else {
                        _option.selected = true;
                    }
                }
                if (_option.selected) {
                    _selectedIds.push(_option.id + "");
                    _hasSelected = true;
                }
            });
            _storageObject[item.field_name] = _selectedIds;
            return item;
        });
        localStorage.setItem('_filters', JSON.stringify(_storageObject));

        for (const key in _storageObject) {
            let _val = _storageObject[key];
            if (_val.length) {
                _historyParams.push(`filters[${key}]=${_storageObject[key]}`);
            }
        }

        if (_historyParams.length) {
            history.push("?" + _historyParams.join('&'));
        } else {
            history.push('');
        }
        if (_hasSelected !== _sideBarState.hasSelectedFilters) {
            _setSideBarState({
                ..._sideBarState,
                hasSelectedFilters: _hasSelected,
            });
        }
    };

    const _handleCheckboxChange = async (e) => {

        let _input = e.target;
        let _filterName = _input.name;
        let _value = _input.value;

        await _updateStorageFilters(_filterName, _value);
        _fetchNews();

    };

    const handleTextSearch = async (value) => {
        _setItemsState({
            ..._itemsState,
            searchTxt: value,
            forceResetTimer: true,
        });
    };

    const _fetchNews = async () => {
        if (!_itemsState.isLoading) {
            _setItemsState({
                ..._itemsState,
                isLoading: true,
                forceResetTimer: false,
            });
        }

        try {
            const _response = await Api.get('/news', {
                params: getSelectedFilters(),
            });

            const _data = await _response.data;
            let _pager = _data.items;
            _setItemsState({
                ..._itemsState,
                items: _data.items.items,
                pager: {..._pager, items: null},
                page: _pager.next,
                fetched: true,
                timerStarted: true,
                forceResetTimer: false,
                isLoading: false
            });

        } catch (e) {
            _setItemsState({
                ..._itemsState,
                isLoading: false,
            });
        }

    };

    const _fetchMoreNews = async () => {
        let _params = getSelectedFilters();
        _params['page'] = _itemsState.page;

        const _response = await Api.get('/news', {
            params: _params,
        });
        const _data = await _response.data;
        let _pager = _data.items;
        let _items = _itemsState.items;
        _items = _items.concat(_data.items.items);
        _setItemsState({
            ..._itemsState,
            items: _items,
            pager: {..._pager, items: null},
            page: _pager.next
        });
    };


    useEffect(() => {

        if (_itemsState.forceResetTimer) {
            _fetchNews();
        } else if (!_itemsState.timerStarted) {
            _fetchSideBar().then(_fetchNews);
        }
        _displayContext.fetchNews = _fetchNews;

        let _timer = setTimeout(() => {
            _fetchNews();
        }, 60000);

        return () => {
            clearTimeout(_timer)
        };
    }, [_itemsState]);

    return (
        <div className='main-content'>
            <div className="container">
                <div className="row">
                    {
                        _displayContext.viewPort.isSmall &&
                        <div className="custom-col-side-2">
                            {_sideBarState.isLoading ? <LazyLoadPlain count={4} className={`default-list-item`}/> :
                                <SideBar items={_sideBarState.items} handleCheckboxChange={_handleCheckboxChange}
                                         handleTextSearch={handleTextSearch}/>}
                        </div>
                    }
                    {_displayContext.viewPort.isLarge && <div className="custom-col-side-1">
                        <div className="side-bar sticky">
                            <CurrencyArea/>
                            <WeatherArea/>
                        </div>
                    </div>}
                    <div className="custom-col-main">
                        {_itemsState.isLoading && ~_itemsState.fetched &&
                            <LazyLoad count={4} className={`default-list-item`}/>}
                        <InfiniteScroll
                            dataLength={_itemsState.items && Array.isArray(_itemsState.items) ? _itemsState.items.length : 0}
                            next={_fetchMoreNews}
                            hasMore={(typeof _itemsState.pager.has_next !== 'undefined') ? _itemsState.pager.has_next : false}
                            loader={<LazyLoad count={8} className={`default-list-item`}/>}
                        >
                            <MainContent _items={_itemsState.items} _hasFilters={_sideBarState.hasSelectedFilters}
                                         _isLoading={_itemsState.isLoading}/>
                        </InfiniteScroll>
                    </div>
                    {
                        !_displayContext.viewPort.isSmall &&
                        <div className="custom-col-side-2">
                            {_sideBarState.isLoading ? <LazyLoadPlain count={4} className={`default-list-item`}/> :
                                <SideBar items={_sideBarState.items} handleCheckboxChange={_handleCheckboxChange}
                                         handleTextSearch={handleTextSearch}/>}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Home;