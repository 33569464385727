import React from 'react';

const AppContext = React.createContext({
    app: {},
    currencies: {},
    weather: {},
    theme: {
        logo: null,
        banners: {},
        colors: {
            color_1: '',
            color_2: '',
        },
    },
});

export default AppContext;