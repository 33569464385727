import React, {useState, useEffect, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import Loader from "../../../Components/Utilities/Loader/Loader";
import Api from "../../../Components/Axios/Api";
import Image from "../../../Components/Utilities/Image/Image";

import './Article.css';
import './../../../Components/Lists/related_items.css';
import NewsItem from "../../../Components/Item/NewsItem";
import InnerPage from "../../../Components/Wrappers/InnerPage/InnerPage";
import {Redirect} from 'react-router-dom';
import useQuery from '../../../Components/Hooks/useQuery';
import {FiPrinter} from 'react-icons/fi';
import Icon from "../../../Components/Utilities/Icon/Icon";
import ShareLinks from "../../ShareLinks/ShareLinks";
import BannersHandler from "../../../Components/Utilities/BannersHandler/BannersHandler";

const Article = props => {

    const history = useHistory();
    const _params = useParams();
    const _query = useQuery();
    const [_isPrintPage, _setIsPrintPage] = useState(false);
    const [_article, _setArticle] = useState({
        item: null,
        fetched: false,
        loading: true,
        relatedItems: [],
        bannersByPosition: [],
        redirect: null,
    });

    if (_query.get('action') !== 'print') {
        if (_isPrintPage) {
            _setIsPrintPage(false);
        }
    }

    const _fetchItemData = async () => {
        if (!_article.loading) {
            _setArticle({
                ..._article,
                loading: true,
            });
        }
        try {
            const _response = await Api.get(`/items/${_params.id}`);

            const _data = await _response.data;
            _setArticle({
                ..._article,
                item: _data.data.item,
                relatedItems: _data.data.related_items,
                loading: false,
                fetched: true,
                bannersByPosition: _data.data.banners,
            });

        } catch (e) {
            _setArticle({
                ..._article,
                isLoading: false,
                redirect: true,
            });
        }
    };

    useEffect(() => {
        if (!_article.fetched || _params.id != _article.item.id) {
            _fetchItemData().then(() => {
                if (_isPrintPage) {
                    window.print();
                }
            });
        } else if (_isPrintPage) {
            window.print();
        }

    }, [_params.id, _isPrintPage]);

    if (_article.redirect) {
        return <Redirect to={`/404`}/>;
    }

    const _handlePrintClick = () => {
        history.push("?action=print");
        _setIsPrintPage(true);
    };

    return (
        <InnerPage className={_isPrintPage ? ' print-page' : ''}>

            <div className={`row ${_isPrintPage ? 'justify-content-center' : ''}`}>
                <div className={_isPrintPage ? `col-md-12 col-xs-12` : `col-md-8 col-xs-12`}>
                    {_article.loading && <div className="inner-page-body">
                        <Loader/>
                    </div>}
                    {!_isPrintPage && !_article.loading && typeof _article.bannersByPosition.top !== 'undefined' &&
                        <BannersHandler className={`side-bar-banner`} display={'slider'}
                                        banners={_article.bannersByPosition.top}/>}
                    {!_article.loading && <React.Fragment>
                        <div className="inner-page-body">
                            <h1 className={`inner-page-title`} dangerouslySetInnerHTML={{__html: _article.item.title}}/>
                            <div className="inner-page-img-w">
                                <Image image={_article.item.image} action={'resize'} width={894} height={470}
                                       size={`custom`}
                                       className={`inner-page-img`}/>
                                <div className="inner-page-img-footer">
                                    <div className="inner-page-date">
                                        {_article.item.date_text}
                                    </div>
                                    {
                                        !_isPrintPage &&
                                        <div>
                                            <button onClick={_handlePrintClick} title={`طباعة الخبر`}>
                                                <Icon className={`square fill`}>
                                                    <FiPrinter title={`طباعة الخبر`}/>
                                                </Icon>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>

                            {!_isPrintPage && typeof _article.bannersByPosition.middle !== 'undefined' &&
                                <BannersHandler className={`side-bar-banner`} display={'slider'}
                                                banners={_article.bannersByPosition.middle}/>}
                            <div className="inner-page-desc"
                                 dangerouslySetInnerHTML={{__html: _article.item.content}}/>
                            {!_isPrintPage && _article.item.tags.length && <div className="inner-page-tags">
                                {_article.item.tags.map((tag, index) => {
                                    return <div key={index} className="inner-page-tag">{`#${tag}`}</div>;
                                })}
                            </div>}
                            {!_isPrintPage &&
                                <ShareLinks title={_article.item.title} url={`/news/${_article.item.id}.html`}/>}
                            {!_isPrintPage && typeof _article.bannersByPosition.bottom !== 'undefined' &&
                                <BannersHandler className={`side-bar-banner`} display={'slider'}
                                                banners={_article.bannersByPosition.bottom}/>}
                        </div>
                    </React.Fragment>}
                </div>
                {
                    !_isPrintPage && <div className="col-md-4 col-xs-12">
                        {_article.loading && <div className="inner-page-body">
                            <Loader/>
                        </div>}
                        {!_article.loading && typeof _article.bannersByPosition.side_bar !== 'undefined' &&
                            <BannersHandler className={`side-bar-banner`} display={'slider'}
                                            banners={_article.bannersByPosition.side_bar}/>}
                        {!_article.loading && _article.relatedItems.length &&
                            <div className={`related-items side-bar-banner`}>
                                <div className="related-items-title">أخبار ذات علاقة</div>
                                {_article.relatedItems.map((_item, _index) => {
                                    return <NewsItem className={`related-item`} item={_item}
                                                     key={`${_item}_${_index}`}/>
                                })}
                            </div>}
                        {!_article.loading && typeof _article.bannersByPosition.side_bar !== 'undefined' &&
                            <BannersHandler className={`side-bar-banner`} display={'slider'}
                                            banners={_article.bannersByPosition.side_bar}/>}
                    </div>
                }
            </div>
        </InnerPage>
    );
};

export default Article;