import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

const DisplayContext = React.createContext({
    isDarkMode: false,
    viewPort: {
        isLarge: true,
        isMedium: false,
        isSmall: false,
    },
    darkModeHandler: () => {
    },
    fetchNews: () => {
    },
    mobileBarItems: [],
    addMobileBarItem: (_key, _icon, _contents) => {
    },
    addOrReplaceMobileBarItem: (_key, _icon, _contents) => {
    },
});

export const DisplayContextProvider = (props) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [mobileBarItemsList, setMobileBarItemsList] = useState({
        items: []
    });

    const darkModeHandler = () => {
        if (isDarkMode) {
            localStorage.removeItem('_darkMode');
            setIsDarkMode(false);
            document.body.classList.remove('dm');
        } else {
            localStorage.setItem('_darkMode', '1');
            setIsDarkMode(true);
            document.body.classList.add('dm');
        }
    }

    const addMobileBarItem = (_key, _icon, _content) => {
        let _exist = false;
        let mobileBarItems = mobileBarItemsList.items;
        mobileBarItems.map((mobileBarItem) => {
            if (mobileBarItem.key === _key) {
                _exist = true;
            }
        });
        if (!_exist) {
            mobileBarItems.push({
                key: _key,
                icon: _icon,
                content: _content,
            });
            setMobileBarItemsList({
                ...mobileBarItemsList,
                items: mobileBarItems,
            });
        }
    }

    const addOrReplaceMobileBarItem = (_key, _icon, _content) => {
        let _exist = false;
        let mobileBarItems = mobileBarItemsList.items;
        mobileBarItems.map((mobileBarItem, index) => {
            if (mobileBarItem.key === _key) {
                _exist = true;
                mobileBarItems[index] = {
                    key: _key,
                    icon: _icon,
                    content: _content,
                };
            }
        });
        if (!_exist) {
            addMobileBarItem(_key, _icon, _content);
        } else {
            setMobileBarItemsList({
                ...mobileBarItemsList,
                items: mobileBarItems,
            });
        }
    }

    const mediaQueryHandler = (matches, bodyClassName) => {
        document.body.classList.remove(bodyClassName);
        if (matches) {
            document.body.classList.add(bodyClassName);
        }
    }

    const viewPort = {
        isLarge: useMediaQuery({minWidth: 982}, undefined, (matches) => {
            mediaQueryHandler(matches, 'view-large')
        }),
        isMedium: useMediaQuery({minWidth: 570, maxWidth: 982}, undefined, (matches) => {
            mediaQueryHandler(matches, 'view-medium')
        }),
        isSmall: useMediaQuery({maxWidth: 570}, undefined, (matches) => {
            mediaQueryHandler(matches, 'view-small')
        }),
    };

    useEffect(() => {
        console.log('DCTX > useEffect', mobileBarItemsList.items);
        if (localStorage.getItem('_darkMode') === '1') {
            darkModeHandler();
        }
    }, [mobileBarItemsList.items]);

    return <DisplayContext.Provider value={{
        isDarkMode: isDarkMode,
        viewPort: viewPort,
        darkModeHandler: darkModeHandler,
        fetchNews: () => {
        },
        mobileBarItems: mobileBarItemsList.items,
        addMobileBarItem: addMobileBarItem,
        addOrReplaceMobileBarItem: addOrReplaceMobileBarItem,
    }}>{props.children}</DisplayContext.Provider>
};

export default DisplayContext;