import React from 'react';

import './LazyLoad.css';
import Loader from "../Loader/Loader";

const LazyLoad = props => {

    let _count = props.count;
    let _items = [];
    for (var i = 0; i < _count; i++) {
        _items.push(<div className={props.className} key={`lazy_${i}`}>
            <div className="default-list-item--head">
                <div className="item-title lazyload"/>
                <span className={'item-date lazyload'}/>
            </div>
            <div className="item-img lazyload">
                <Loader className={`sm`}/>
            </div>
            <div className="item-body">
                <span className={'item-date lazyload'}/>
                <div className="item-desc lazyload"/>
                <div className="item-desc lazyload"/>
            </div>
        </div>);
    }
    return _items;
};

export default LazyLoad;