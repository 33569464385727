import React from 'react';
import './SideBar.css';
import Filter from "./Filters/Filter";
import Banner from "../../Banners/Banner";
import SearchInput from "./SearchInput/SearchInput";
import BannersHandler from "../../../Components/Utilities/BannersHandler/BannersHandler";

const SideBar = props => {

    let {handleCheckboxChange, handleTextSearch, items} = {...props};

    return (
        <div className='side-bar sticky'>
            <div className='filters'>
                <SearchInput onBtnClick={handleTextSearch}/>
                {
                    items.map((item, index) => {
                        if (item.type === 'banner') {
                            return (
                                <div className={`side-bar-banner`} key={item.id + '_' + index}>
                                    <BannersHandler display={`slider`} banners={[item]}/>
                                </div>
                            );
                        } else if (item.type === 'filter') {
                            return (
                                <Filter item={item} key={item.name + '_' + index}
                                        handleCheckboxChange={handleCheckboxChange}/>
                            );
                        }
                    })
                }
            </div>
        </div>
    );
};

export default SideBar;