import React from 'react';

import './LazyLoad.css';
import Loader from "../Loader/Loader";

const LazyLoadPlain = props => {

    let _count = props.count;
    let _items = [];
    for (var i = 0; i < _count; i++) {
        _items.push(<div className={`${props.className} mb-10`} key={`lazy_${i}`}>
            <div className="item-img">
                <Loader className={`sm`}/>
            </div>
        </div>);
    }
    return (
        <React.Fragment>
            {_items}
        </React.Fragment>
    );
};

export default LazyLoadPlain;