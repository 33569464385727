import React, {useContext} from 'react';
import DisplayContext from "../../../Context/DisplayContext";
import BannersSlider from "../../../modules/BannersSlider/BannersSlider";
import Banner from "../../../modules/Banners/Banner";

const BannersHandler = props => {

    const _displayContext = useContext(DisplayContext);
    const {banners, display, className} = {...props};
    let _list = [];

    banners.map((_banner) => {
        if (_displayContext.viewPort.isLarge && !_banner.is_desktop) {
            return false;
        }
        if (_displayContext.viewPort.isMedium && !_banner.is_tablet) {
            return false;
        }
        if (_displayContext.viewPort.isSmall && !_banner.is_mobile) {
            return false;
        }
        _list.push(_banner);
    });
    if (_list.length === 0) {
        return <React.Fragment/>;
    }
    if (display === 'slider') {
        return <BannersSlider className={className} items={_list}/>
    }

    return <React.Fragment>
        {_list.map((_banner, _index) => {
            return <Banner className={className} item={_banner} key={`banner_${_index}_${_banner.id}`}/>
        })}
    </React.Fragment>

};

export default BannersHandler;