import React, {useContext} from 'react';
import AppContext from "../../Context/AppContext";
import {FaWhatsapp, FaEnvelope, FaFacebookF, FaLinkedinIn, FaTwitter} from 'react-icons/fa';

import './ShareLinks.css';
import Icon from "../../Components/Utilities/Icon/Icon";

const ShareLinks = props => {
    const _appContext = useContext(AppContext);
    let _link = _appContext.app.base_url.replace(/^\/|\/$/g, '') + props.url.trim('/');
    let _title = props.title;

    return (
        <div className={'share-links-wrapper'}>
            <div className="share-link-title">شارك الخبر:</div>
            <ul className={`share-links reset`}>
                <li key={`share_facebook`}>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${_link}&display=popup&ref=plugin&src=like`}
                       className="share-link-item facebook" target={`_blank`} title={`شارك`}>
                        <Icon className={`share-link-icon facebook fill`}>
                            <FaFacebookF title={`شارك`}/>
                        </Icon>
                    </a>
                </li>
                <li key={`share_twitter`}>
                    <a href={`https://twitter.com/intent/tweet?text=${_title}&url=${_link}&original_referer=${_link}`}
                       className="share-link-item twitter" target={`_blank`} title={`شارك`}>
                        <Icon className={`share-link-icon twitter fill`}>
                            <FaTwitter title={`شارك`}/>
                        </Icon>
                    </a>
                </li>
                <li key={`share_mail`}>
                    <a href={`mailto:?subject=${_title}&body=${_link}`}
                       className="share-link-item mail" target={`_blank`} title={`شارك`}>
                        <Icon className={`share-link-icon mail fill`}>
                            <FaEnvelope title={`شارك`}/>
                        </Icon>
                    </a>
                </li>
                <li key={`share_linkedin`}>
                    <a href={`https://www.linkedin.com/shareArticle?url=${_link}&title=${_title}&source=${_appContext.app.base_url}`}
                       className="share-link-item linkedin" target={`_blank`} title={`شارك`}>
                        <Icon className={`share-link-icon linkedin fill`}>
                            <FaLinkedinIn title={`شارك`}/>
                        </Icon>
                    </a>
                </li>
                <li key={`share_whatsapp`}>
                    <a href={`https://api.whatsapp.com/send?text=${_link}`}
                       className="share-link-item whatsapp" target={`_blank`} title={`شارك`}>
                        <Icon className={`share-link-icon whatsapp fill`}>
                            <FaWhatsapp title={`شارك`}/>
                        </Icon>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default ShareLinks;