import React, {useContext} from 'react';
import './Header.css';
import {Link} from 'react-router-dom';
import AppContext from "../../Context/AppContext";
import DarkMode from "../../Components/Utilities/DarkMode/DarkMode";
import useQuery from "../../Components/Hooks/useQuery";
import BreakingNews from "../BreakingNews/BreakingNews";
import DisplayContext from "../../Context/DisplayContext";
import PostHeaderArea from "../Areas/PostHeaderArea/PostHeaderArea";
import BannersHandler from "../../Components/Utilities/BannersHandler/BannersHandler";

const Header = props => {

    const _appContext = useContext(AppContext);
    const _displayContext = useContext(DisplayContext);
    const _query = useQuery();
    let _isPrintPage = false;
    if (_query.get('action') === 'print') {
        _isPrintPage = true;
    }

    let _title = '';
    if (typeof _appContext.app.name !== 'undefined' && _appContext.app.name.length) {
        _title = _appContext.app.name;
    }

    return (
        <div className={`sticky-content`} id={`stickyHeight`}>
            <header className={_isPrintPage ? 'print-page' : ''}>
                <div className="container">
                    <div className="header-items">
                        <div className="header-item-pre">
                            <Link to={'/'} title={_title} className="header-logo">
                                {
                                    typeof _appContext.theme.logo !== 'undefined' &&
                                    <div className="image">
                                        <img src={_appContext.theme.logo} alt={_title} width={90} height={90}/>
                                    </div>
                                }
                            </Link>
                        </div>
                        <div className="header-item-mid">
                            {
                                !_isPrintPage && <BannersHandler display={`slider`} banners={_appContext.theme.header_banners}/>
                            }
                        </div>
                        {
                            _displayContext.viewPort.isLarge &&
                            <div className="header-item-post">
                                <PostHeaderArea/>
                                <div className="header-item-top">
                                    <DarkMode/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </header>
            {_isPrintPage ? '' : <BreakingNews/>}
        </div>
    );
};

export default Header;