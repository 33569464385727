import React, {useState, useContext} from 'react';

import './Weather.css';
import {FiSun} from "react-icons/fi";
import AppContext from "../../../Context/AppContext";
import Select from "../../Elements/Select/Select";

const Weather = props => {
    const _appContext = useContext(AppContext);

    const _defaultCookieValue = localStorage.getItem('_weather_city');
    const [_weatherState, _setWeatherState] = useState({
        selectedCity: _defaultCookieValue
    });

    if (typeof _appContext.weather === 'undefined' || !Object.values(_appContext.weather).length) {
        return <React.Fragment/>
    }

    let _selectedId = _weatherState.selectedCity;
    let _options = [];
    Object.values(_appContext.weather).map((city) => {
        if(!_selectedId && city.selected){
            _selectedId = city.id;
        }
        _options.push({value: city.id, label: city.label, selected: _selectedId === city.id});
    });

    if (!_selectedId) {
        _setWeatherState({
            ..._weatherState,
            selectedCity: _selectedId,
        })
    }


    const handleCityChange = (event) => {
        let _selectedId = event.target.value;

        localStorage.setItem('_weather_city', _selectedId);
        _setWeatherState({
            ..._weatherState,
            selectedCity: _selectedId,
        })
    }

    return (
        <div className="simple-table box">
            <div className="simple-table--title-w">
                <div className="simple-table--title">
                    <FiSun className={`simple-table--icon weather--icon`}/>
                    <span>الطقس</span>
                </div>
                <Select className={`weather-select`} name={`weather_city_id`} options={_options}
                        onChange={handleCityChange}/>
            </div>
            {
                Object.values(_appContext.weather).map((city) => {
                    return <div
                        className={'weather--content ' + (_selectedId === city.id ? 'active' : '')}
                        key={city.id} id={city.id}>
                        {
                            Object.values(city.weather).map((cityWeather, index) => {
                                return <div className="weather--item" key={index}>
                                    <div className="weather-item--title">{cityWeather.day}</div>
                                    <div className="weather-item--value">
                                        °{cityWeather.high} / °{cityWeather.low}
                                    </div>
                                </div>
                            })
                        }
                    </div>;
                })
            }
        </div>
    );
};

export default Weather;