import React, {useEffect, useState} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import Api from "../../../Components/Axios/Api";

import './Text.css';
import './../../../Components/Lists/related_items.css';
import InnerPage from "../../../Components/Wrappers/InnerPage/InnerPage";
import Loader from "../../../Components/Utilities/Loader/Loader";
import Banner from "../../Banners/Banner";
import Image from "../../../Components/Utilities/Image/Image";
import Icon from "../../../Components/Utilities/Icon/Icon";
import {FiPrinter} from "react-icons/fi";
import ShareLinks from "../../ShareLinks/ShareLinks";
import NewsItem from "../../../Components/Item/NewsItem";
import useQuery from "../../../Components/Hooks/useQuery";

const Text = props => {
    const _params = useParams();
    const [_page, _setPage] = useState({
        item: null,
        fetched: false,
        loading: true,
        redirect: null,
    });
    let _query = useQuery();
    let _isPrintPage = false;
    if (_query.get("action") === 'print') {
        _isPrintPage = true;
    }

    const _fetchPageData = async () => {
        if (!_page.loading) {
            _setPage({
                ..._page,
                loading: true,
            });
        }
        try {
            const _response = await Api.get(`/pages/${_params.slug}`);

            const _data = await _response.data;
            _setPage({
                ..._page,
                item: _data.data.item,
                loading: false,
                fetched: true,
            });

        } catch (e) {
            _setPage({
                ..._page,
                isLoading: false,
                redirect: true,
            });
        }
    };

    useEffect(() => {
        if (!_page.fetched || _params.slug !== _page.slug) {
            _fetchPageData();
        }
    }, [_params.slug]);

    if (_page.redirect) {
        return <Redirect to={`/404`}/>;
    }

    return (
        <InnerPage className={_isPrintPage ? ' print-page' : ''}>
            <div className={`row justify-content-center`}>
                <div className={`col-md-9`}>
                    {_page.loading && <div className="inner-page-body">
                        <Loader/>
                    </div>}
                    {!_page.loading && <React.Fragment>
                        <div className="inner-page-body">
                            <h1 className={`inner-page-title`} dangerouslySetInnerHTML={{__html: _page.item.title}}/>
                            {_page.item.image && <div className="inner-page-img-w">
                                <Image image={_page.item.image} action={'resize'} width={894} height={470}
                                       size={`custom`}
                                       className={`inner-page-img`}/>
                            </div>}
                            <div className="inner-page-desc"
                                 dangerouslySetInnerHTML={{__html: _page.item.content}}/>
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        </InnerPage>
    );
};

export default Text;