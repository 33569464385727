import React from 'react';

import InnerPage from "../../../Components/Wrappers/InnerPage/InnerPage";
import Alert from "../../../Components/Utilities/Alerts/Alert";

const NotFound = props => {

    return (
        <InnerPage>
            <Alert msg={`الرابط الذي تحاول الوصول إليه ربما تم نقله أو حذفه!`} type={`danger`}/>
        </InnerPage>
    );
};

export default NotFound;