import React, {useContext} from 'react';
import DisplayContext from "../../Context/DisplayContext";
import AppContext from "../../Context/AppContext";
import {
    FaTelegramPlane,
    FaYoutube,
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaTiktok,
    FaSnapchatGhost,
    FaWhatsapp,
} from 'react-icons/fa';

import './SocialMedia.css';
import Icon from "../../Components/Utilities/Icon/Icon";

const SocialMedia = props => {
    const _displayContext = useContext(DisplayContext);
    const _appContext = useContext(AppContext);

    if (_displayContext.isPrintPage || (typeof _appContext.app.social_media === 'undefined' || !_appContext.app.social_media.length)) {
        return <React.Fragment/>
    }

    const _getIconByName = (_name, _title) => {
        let _iconName = _name.toLowerCase();
        switch (_iconName) {
            case 'facebook':
                return <FaFacebookF title={_title}/>;
            case 'youtube':
                return <FaYoutube title={_title}/>;
            case 'telegram':
                return <FaTelegramPlane title={_title}/>;
            case 'instagram':
                return <FaInstagram title={_title}/>;
            case 'twitter':
                return <FaTwitter title={_title}/>;
            case 'tiktok':
                return <FaTiktok title={_title}/>;
            case 'snapchat':
                return <FaSnapchatGhost title={_title}/>;
            case 'whatsapp':
                return <FaWhatsapp title={_title}/>;
        }
    };

    let _links = [];
    _appContext.app.social_media.map((_item, _index) => {
        if (typeof _item.link !== 'undefined' && _item.link.length) {
            _links.push(<li key={`${_item.id}_${_index}`}>
                <a href={_item.link} className={`social-media-item ${_item.id}`} title={_item.name} target={`_blank`}>
                    <Icon className={`social-media-icon ${_item.id}`}>
                        {_getIconByName(_item.id, _item.name)}
                    </Icon>
                </a>
            </li>);
        }
    });

    return (
        <div className={'social-media'}>
            <ul className={`social-media-links reset`}>
                {_links}
            </ul>
        </div>
    );
};

export default SocialMedia;