import React, {useEffect, useState} from 'react';

import './BannersSlider.css';
import Banner from "../Banners/Banner";

const BannersSlider = props => {

    const [_activeIndex, _setActiveIndex] = useState(0);

    let _itemsArray = [];
    props.items.map((item) => {
        _itemsArray.push(<div className={`banner-slide`} key={'sliding_banner_' + item.id}><Banner item={item}/>
        </div>);
    });

    useEffect(() => {
        const _interval = setInterval(() => {
            let _index = _activeIndex;
            _index = _index + 1;
            if (typeof _itemsArray[_index] === 'undefined') {
                _index = 0;
            }
            _setActiveIndex(_index);
        }, 7000);
        return () => clearInterval(_interval);
    }, [_activeIndex]);

    if (typeof props.items === 'undefined' || !props.items.length) {
        return false;
    }
    const _className = 'banner-slide';
    let _parentClassName = 'banners-slider';
    if (typeof props.className !== 'undefined') {
        _parentClassName += ` ${props.className}`;
    }

    return (
        <div className={_parentClassName}>
            {
                props.items.map((item, index) => {
                    let _className2 = 'hidden';
                    if (index === _activeIndex) {
                        _className2 = 'active';
                    }
                    _className2 = _className + ' ' + _className2
                    return <div className={_className2} key={'sliding_banner_' + item.id}><Banner item={item}/>
                    </div>;
                })
            }
        </div>
    );
};

export default BannersSlider;