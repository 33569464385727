import React from 'react';
import {Link} from 'react-router-dom';
import Image from "../Utilities/Image/Image";

const NewsItem = props => {

    let {className} = {...props};
    const {item} = {...props};

    if (typeof className === 'undefined' || !className.length) {
        className = 'default-list-item';
    }

    let _tags = [];
    if (item.tags.length) {
        item.tags.map((tag, index) => {
            _tags.push(<div key={index} className="item-tag">{tag}</div>);
        });
    }

    let _tags2 = [];
    if (item.tags.length) {
        item.tags.map((tag, index) => {
            _tags2.push(<div key={index} className="head-tag">{`#` + tag}</div>);
        });
    }

    return (
        <Link to={`/news/${item.id}.html`} className={className} title={item.title}>
            <div className="default-list-item--head">
                <h2 dangerouslySetInnerHTML={{__html: item.title}} className="item-title"/>
                <span className={'item-date'}>{item.date_text}</span>
            </div>
            <div className="item-img">
                <Image image={item.image} size={`custom`} action={`crop`} width={680} height={382} alt={item.title}/>
                {_tags.length ? <div className={`item-tags`}>
                    {_tags}
                </div> : ''}
            </div>
            <div className="item-body">
                {_tags2.length ? <div className={`head-tags`}>
                    {_tags2}
                </div> : ''}
                {item.excerpt}
            </div>
        </Link>
    );
};

const _getTitle = (_title) => {
    if (_title.length > 80) {
        return _title.substr(0, 77) + '...';
    }
    return _title;
};

export default NewsItem;