import React from 'react';

const Select = props => {

    let _selectProps = {...props};
    const {options} = {..._selectProps};
    delete _selectProps.options;

    if (!options.length) {
        return <React.Fragment/>;
    }
    let _defaultValue = null;
    options.map((option) => {
        if (option.selected) {
            _defaultValue = option.value;
        }
    })

    return (
        <React.Fragment>
            <select {..._selectProps} defaultValue={_defaultValue}>
                {options.map((option) => {
                    return <option value={option.value} key={option.value}>
                        {option.label}
                    </option>
                })}
            </select>
        </React.Fragment>
    );
};

export default Select;