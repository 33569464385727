import React from 'react';

import './InnerPage.css';

const InnerPage = props => {

    let {className} = {...props};

    if (typeof className === 'undefined') {
        className = '';
    }
    className = 'inner-page' + ` ${className}`;

    return (
        <div className={className}>
            <div className="container">
                {props.children}
            </div>
        </div>
    );
};

export default InnerPage;