import React, {useContext, useState} from 'react';
import DisplayContext from "../../Context/DisplayContext";
import {AiFillHome} from 'react-icons/ai';

import './MobileBar.css';
import {FiDollarSign, FiSun} from "react-icons/fi";
import CurrencyArea from "../Areas/CurrencyArea/CurrencyArea";
import WeatherArea from "../Areas/WeatherArea/WeatherArea";
import DarkMode from "../../Components/Utilities/DarkMode/DarkMode";
import PostHeaderArea from "../Areas/PostHeaderArea/PostHeaderArea";
import {IoMdShare} from "react-icons/io";
import {FaFilter} from "react-icons/fa";

const MobileBar = props => {

    const [fixedSideState, setFixedSideState] = useState({
        active: false,
        activeKey: 'home',
        contents: null,
    });

    const _displayContext = useContext(DisplayContext);
    if (_displayContext.isPrintPage) {
        return <React.Fragment/>;
    }

    const _handlePageClick = (_key, contents) => {
        if (_key === 'darkmode') {
            _displayContext.darkModeHandler();
        } else if (_key === 'home') {
            _displayContext.fetchNews();
            setFixedSideState({
                ...fixedSideState,
                active: false,
                activeKey: _key,
                contents: null,
            });
        } else {
            setFixedSideState({
                ...fixedSideState,
                active: true,
                activeKey: _key,
                contents: contents,
            });
        }
    }

    const _getBarItem = (_icon, _key, _content) => {
        return (
            <div key={_key} className={`mobile-bar--item ${_key === fixedSideState.activeKey ? 'active' : ''}`}
                 onClick={() => _handlePageClick(_key, _content)}
            >{_icon}</div>
        );
    }

    let _components = [];
    _components.push(_getBarItem(<AiFillHome/>, 'home'));
    _components.push(_getBarItem(<DarkMode/>, 'darkmode'));
    _components.push(_getBarItem(<FiDollarSign/>, 'currency', <CurrencyArea/>));
    _components.push(_getBarItem(<FiSun/>, 'weather', <WeatherArea/>));
    _components.push(_getBarItem(<IoMdShare/>, 'social', <PostHeaderArea/>));

    _displayContext.mobileBarItems.map((mobileBarItem) => {
        _components.push(_getBarItem(mobileBarItem.icon, mobileBarItem.key, mobileBarItem.content));
    });

    return (
        <React.Fragment>
            <div className={`fixed-side-component ${fixedSideState.active ? 'active' : ''}`}>
                {fixedSideState.contents}
            </div>
            <div className="mobile-bar">
                <div className="mobile-bar--items">
                    {_components}
                </div>
            </div>
        </React.Fragment>
    );
};

export default MobileBar;