import React, {useContext} from "react";
import AppContext from "../../../Context/AppContext";
import BannersSlider from "../../BannersSlider/BannersSlider";
import Currencies from "../../../Components/Utilities/Currencies/Currencies";
import BannersHandler from "../../../Components/Utilities/BannersHandler/BannersHandler";

const CurrencyArea = () => {

    const _appContext = useContext(AppContext);

    let _rightSideBannerTop = '';
    if (typeof _appContext.theme.banners !== 'undefined' && typeof _appContext.theme.banners['right_side'] !== 'undefined') {
        if (typeof _appContext.theme.banners.right_side['position_1'] !== 'undefined') {
            _rightSideBannerTop =
                <BannersHandler className={`mb-10`} display={`slider`} banners={_appContext.theme.banners.right_side.position_1}/>;
        }
    }

    return <React.Fragment>
        <Currencies/>
        {_rightSideBannerTop}
    </React.Fragment>;

}

export default CurrencyArea;