import React, {useContext} from 'react';
import DisplayContext from "../../Context/DisplayContext";

import './Footer.css';
import MobileBar from "../MobileBar/MobileBar";

const Footer = props => {
    const _displayContext = useContext(DisplayContext);
    if (!_displayContext.viewPort.isLarge) {
        return <MobileBar/>;
    }

    const _year = (new Date()).getFullYear();

    return (
        <footer className={_displayContext.isPrintPage ? '' : 'fixed'}>
            <div className="footer-text">
                {`جميع الحقوق محفوظة © ${_year}`}
            </div>
        </footer>
    );
};

export default Footer;