import React, {useContext} from 'react';

import './../../../Components/Shared/simple_table.css';
import {FiDollarSign} from "react-icons/fi";
import AppContext from "../../../Context/AppContext";

const Currencies = props => {
    const _appContext = useContext(AppContext);

    if (typeof _appContext.currencies === 'undefined' || !Object.values(_appContext.currencies).length) {
        return <React.Fragment/>
    }
    return (
        <div className="simple-table box">
            <div className="simple-table--title">
                <FiDollarSign className={`simple-table--icon`}/>
                <span>أسعار العملات</span>
            </div>
            {Object.values(_appContext.currencies).map((currency) => {
                return (
                    <div className="simple-table--row" key={currency.id}>
                        <div className="simple-table--th">{currency.label}</div>
                        <div className="simple-table--td-w">
                            <div className="simple-table--td">
                                <div className="simple-table-td--label">بيع:</div>
                                <div className="simple-table-td--value">{currency.sell}</div>
                            </div>
                            <div className="simple-table--td">
                                <div className="simple-table-td--label">شراء:</div>
                                <div className="simple-table-td--value">{currency.buy}</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Currencies;