import React from 'react';

import {FaCheckCircle, FaSearch} from 'react-icons/fa';

const Filter = props => {

    const {item, handleCheckboxChange} = {...props};

    return (
        <React.Fragment>
            <div className={'filter-item'}>
                <label className="filter-label">{item.label}</label>
                {item.options.map((option, index) => {
                    let _name = item.name;
                    let _id = item.name + `[${option.id}]`;
                    return <div className="filter-item-option" key={option.id}>
                        <label className="filter-item-label" htmlFor={_id}>
                            <input className="filter-item-input" type="checkbox" id={_id}
                                   value={option.id}
                                   checked={option.selected}
                                   name={_name} onChange={handleCheckboxChange}/>
                            <FaCheckCircle className={`filter-item-icon`}/>
                            {option.label}
                        </label>
                    </div>
                })}
            </div>
        </React.Fragment>
    );
};

export default Filter;