import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';

import Home from './modules/Home/Home';
import Header from "./modules/Header/Header";
import Footer from "./modules/Footer/Footer";
import Article from './modules/Pages/Article/Article';
import NotFound from "./modules/Pages/404/NotFound";
import {IconContext} from "react-icons";
import AppContext from "./Context/AppContext";
import Api from "./Components/Axios/Api";
import Loader from "./Components/Utilities/Loader/Loader";
import Text from "./modules/Pages/Text/Text";
import {DisplayContextProvider} from "./Context/DisplayContext";

function App() {

    let _stylesAreAppended = false;

    const _settingsInitial = {
        loaded: false,
        app: {},
        currencies: {},
        weather: {},
        theme: {
            logo: null,
            banners: {},
            colors: {
                color_1: '#011e36',
                color_2: '#ffa218',
            },
        },
    };
    const [_settings, _setSettings] = useState(_settingsInitial);
    const _getSettings = async () => {
        try {
            const _response = await Api.get('/settings');
            const _data = await _response.data;
            _setSettings({
                ..._data.data,
                loaded: true,
            });

        } catch (e) {
            _setSettings({
                loaded: true,
                ..._settings,
            });
        }
    };


    useEffect(() => {
        if (!_settings.loaded) {
            _getSettings();
        }

        if (!_stylesAreAppended) {
            let _colors = [];
            if (typeof _settings.theme.colors !== 'undefined') {
                if (typeof _settings.theme.colors.color_1 !== 'undefined') {
                    _colors.push('color-1:' + _settings.theme.colors.color_1);
                }
                if (typeof _settings.theme.colors.color_2 !== 'undefined') {
                    _colors.push('color-2:' + _settings.theme.colors.color_2);
                }
            }
            if (_colors.length) {

                let _style = document.createElement("style");
                let _styleTxt = ':root{';
                _colors.map((_color) => {
                    _styleTxt += `--${_color};`;
                });
                _styleTxt += '}';

                document.head.appendChild(_style);
                _style.innerHTML = _styleTxt;

            }
            _stylesAreAppended = true;
        }
    }, [_settings.theme.colors]);

    return (
        <AppContext.Provider value={_settings}>
            <DisplayContextProvider>
                <IconContext.Provider value={{size: `18px`, className: `svg-icon`}}>
                    {!_settings.loaded && <Loader isPageLoader={true}/>}
                    {_settings.loaded && <div>
                        <Header/>
                        <Switch>
                            <Route path={`/`} exact>
                                <Home/>
                            </Route>
                            <Route path={`/news/:id.html`} exact>
                                <Article/>
                            </Route>
                            <Route path={`/404`} exact>
                                <NotFound/>
                            </Route>
                            <Route path={`/:slug`} exact>
                                <Text/>
                            </Route>
                            <Route>
                                <NotFound/>
                            </Route>
                        </Switch>
                        <Footer/>
                    </div>}
                </IconContext.Provider>
            </DisplayContextProvider>
        </AppContext.Provider>
    );
}

export default App;