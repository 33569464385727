import React, {useContext} from "react";
import AppContext from "../../../Context/AppContext";
import BannersSlider from "../../BannersSlider/BannersSlider";
import Currencies from "../../../Components/Utilities/Currencies/Currencies";
import DisplayContext from "../../../Context/DisplayContext";
import {Link} from "react-router-dom";
import SocialMedia from "../../SocialMedia/SocialMedia";
import useQuery from "../../../Components/Hooks/useQuery";

const PostHeaderArea = () => {

    const _appContext = useContext(AppContext);
    const _displayContext = useContext(DisplayContext);
    const _query = useQuery();

    let _isPrintPage = false;
    if (_query.get('action') === 'print') {
        _isPrintPage = true;
    }

    let _pages = [];
    _appContext.pages.map((page, index) => {
        _pages.push(<li key={index}><Link to={`/${page.slug}`} className="header-menu--item">{page.title}</Link>
        </li>);
    })
    if (_isPrintPage) {
        return <React.Fragment/>;
    }

    return <React.Fragment>
        <div className="header-item-top">
            <ul className={'header-menu reset'}>
                {_pages}
            </ul>
        </div>
        <div className="header-item-top">
            <SocialMedia/>
        </div>
    </React.Fragment>;

}

export default PostHeaderArea;