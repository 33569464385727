import React, {useContext} from "react";
import AppContext from "../../../Context/AppContext";
import BannersSlider from "../../BannersSlider/BannersSlider";
import Weather from "../../../Components/Utilities/Weather/Weather";
import BannersHandler from "../../../Components/Utilities/BannersHandler/BannersHandler";

const WeatherArea = () => {

    const _appContext = useContext(AppContext);

    let _rightSideBannerBottom = '';
    if (typeof _appContext.theme.banners !== 'undefined' && typeof _appContext.theme.banners['right_side'] !== 'undefined') {
        if (typeof _appContext.theme.banners.right_side['position_2'] !== 'undefined') {
            _rightSideBannerBottom =
                <BannersHandler display={`slider`} banners={_appContext.theme.banners.right_side.position_2}/>
        }
    }

    return <React.Fragment>
        <Weather/>
        {_rightSideBannerBottom}
    </React.Fragment>;

}

export default WeatherArea;