import React from 'react';

import './Alert.css';

const Alert = props => {

    let _typeClasses = ['success', 'danger', 'warning', 'info'];
    let {msg, type} = {...props};
    if (!_typeClasses.includes(type)) {
        type = 'success';
    }

    return (
        <div className={`alert alert-${type}`}>{msg}</div>
    );
};

export default Alert;